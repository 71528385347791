export default {
  namespace: "leftActivity",
  state: [],
  reducers: {
    storage(state, { payload }) {
      return [...payload];
    },
    clear() {
      return [];
    },
    pushItem(state, { payload }) {
      return [...state, payload];
    },
    updateValid(state, { payload }) {
      let _state = state.map((state_item) => {
        return { ...state_item, valid: payload.valid };
      });
      return [..._state];
    },
  },
};
