//保存全局的状态
export default {
  namespace: "editActivity",
  state: {
    isShowCoupon: false,
    modal_get_coupon_tips: "Congratulations on getting this one-time offer！",
    modal_countdown_tips: "Offer will end in {num}",
    modal_get_coupon_button: "GET MY SAVINGS",
    enable_countdown_time: 20,
    temporaryStoreData: {},
    isShowTime: false,
  },
  reducers: {
    storage(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
