export default {
  namespace: "recommendationProducts",
  state: {},
  reducers: {
    storage(state, { payload }) {
      let result = { ...state, ...payload };
      return result;
    },
    restore() {
      return {};
    },
  },
};
