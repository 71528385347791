interface IDashboard {
  timezone: string;
}
export default {
  namespace: "dashboard",
  state: { timezone: "Asia/Shanghai" } as IDashboard,
  reducers: {
    storage(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
