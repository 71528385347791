//保存全局的状态
export default {
  namespace: "common",
  state: {
    globalRegion: "",
  },
  reducers: {
    storage(state, { payload }) {
      return { ...state, ...payload };
    },
  },
};
