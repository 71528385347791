//保存全局的状态
export default {
  namespace: "anotherOrder",
  state: {
    orderData: {},
    activeShowType: "", //二级页面类型
    checkFormValidType: "",
    showAnimated: true,
    showPreview: false,
    showDown: false,
    defaultMenuActiveKeys: [],
    temporaryStoreData: {},
    isRecommendInfoEdit: false, //推荐文案编辑状态
    scrollTop: 0, //滚动的距离
  },
  reducers: {
    storage(state, { payload }) {
      // console.log("anotherOrderChange", payload);
      return { ...state, ...payload };
    },
  },
};
